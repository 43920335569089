import React from "react";
import styled from "@emotion/styled";
// utils
import { Color } from "../../utils/color";

export const PreparationSection = (props) => {
  return (
    <SSection>
      <SContainer>
        <p>{props.txt}ページ</p>
        <p>準備中</p>
      </SContainer>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Color.gray100};
  border-radius: 8px;
`;
