import React from "react";
// components
import { DefaultLayout } from "../../components/layout/defaultLayout";
import { BreadcrumbBar } from "../../components/bar/breadcrumbBar";
import { PreparationSection } from "../../components/section/preparationSection";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "マイページ", url: "/mypage" },
  { txt: "カート", url: "/mypage/cart" },
];

const CartPage = () => {
  return (
    <DefaultLayout pageTitle="カート">
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <PreparationSection txt="CART" />
    </DefaultLayout>
  );
};

export default CartPage;
